// src/pages/ScriptPage.js

import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Button, CircularProgress, TableContainer, Table,TableHead, TableBody, TableCell, TableRow } from '@mui/material';
import axiosInstance from '../api/axiosInstance';
import { useParams } from 'react-router-dom';

const ScriptPage = () => {
  const { scriptId } = useParams();
  const [plays, setPlays] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPlays = async () => {
      try {
        const response = await axiosInstance.get(`/scripts/${scriptId}/plays/`);
        setPlays(response.data);
      } catch (error) {
        console.error('Fehler beim Abrufen der Plays:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchPlays();
  }, [scriptId]);

  const handleAddPlay = async (playType) => {
    try {
      const newPlay = { play_type: playType, play_number: plays.length + 1 };
      const response = await axiosInstance.post(`/scripts/${scriptId}/plays/`, newPlay);
      setPlays((prev) => [...prev, response.data]);
    } catch (error) {
      console.error('Fehler beim Hinzufügen eines Plays:', error);
    }
  };

  if (loading) return <CircularProgress />;

  return (
    <>
      <Typography variant="h4" gutterBottom>
        LivePlays für Script #{scriptId}
      </Typography>
      <Button variant="contained" color="primary" onClick={() => handleAddPlay('Offense')}>
        Neues Offense Play
      </Button>
      <Button variant="contained" color="success" sx={{ ml: 2 }} onClick={() => handleAddPlay('Defense')}>
        Neues Defense Play
      </Button>
      <Button variant="contained" color="error" sx={{ ml: 2 }} onClick={() => handleAddPlay('Special Teams')}>
        Neues Special Teams Play
      </Button>
      {/* Tabelle */}
      <TableContainer sx={{ mt:2 }}>
        <Table stickyHeader aria-label="playbook types table">
          <TableHead>
            <TableRow>
              <TableCell>Play Number</TableCell>
              <TableCell>Play Type</TableCell>
              <TableCell>Off Formation</TableCell>
              <TableCell>Motion</TableCell>
              <TableCell>Off Play</TableCell>
              <TableCell>Def Front</TableCell>
              <TableCell>Blitz</TableCell>
              <TableCell>Coverage</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {plays.map((play) => (
            <TableRow key={play.id}>
              <TableCell>{play.play_number}</TableCell>
              <TableCell>{play.play_type}</TableCell>
              <TableCell>{play.off_form}</TableCell>
              <TableCell>{play.motion}</TableCell>
              <TableCell>{play.off_play}</TableCell>
              <TableCell>{play.def_front}</TableCell>
              <TableCell>{play.blitz}</TableCell>
              <TableCell>{play.coverage}</TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ScriptPage;
