// App.js
import React, { useEffect, useState,Suspense, lazy, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axiosInstance from './api/axiosInstance';
import ErrorBoundaryWithSnackbar from './components/ErrorBoundary';
import { SnackbarProvider, useSnackbar } from './components/SnackbarContext';
import Dashboard from './Dashboard';
import ContainerDashboard from './components/ContainerDashboard';
import PracticeManagement from './PracticeManagement';
import PracticeForm from './PracticeForm';
import LivePractice from './LivePractice';
import LivePracticeTable from './LivePracticeTable';
import Sidebar from './components/Sidebar';
import { Grid, Box, useMediaQuery } from '@mui/material';
import UserManagementPage from './pages/UserManagementPage';
import PlaybookPage from './pages/PlaybookPage';
import PlaybookTypesPage from './pages/PlaybookTypesPage';
import PlaybookTagsPage from './pages/PlaybookTagsPage';
import PlayDetailPage from './pages/PlayDetailPage';
import PlayTablePage from './pages/PlayTablePage';
import PeriodActivityTable from './components/EventDetails/PeriodActivityTable';
import TeamEventsPage from './pages/TeamEventsPage';
import PracticeDetailPage from './pages/PracticeDetailPage';
import ScriptPage from './pages/ScriptPage';
import './assets/scss/App.scss';
import DashboardLayout from './components/DashboardLayout';
import { PlaybookProvider } from './contexts/PlaybookContext';
import { GlobalProvider } from './contexts/GlobalContext';

function App({ onLogout }) {
    const [selectedOrganisation, setSelectedOrganisation] = useState([]);
    const [organisations, setOrganisations] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState([]);
    const [selectedOffensePlaybook, setSelectedOffensePlaybook] = useState([]);
    const [selectedDefensePlaybook, setSelectedDefensePlaybook] = useState([]);
    const [selectedSpecialPlaybook, setSelectedSpecialPlaybook] = useState([]);
    const isMobile = useMediaQuery('(max-width:600px)'); // Breakpoint für Mobilgeräte
   // const { showSnackbar } = useSnackbar();

    useEffect(() => {
        fetchOrganisations();
    }, []);

    const fetchOrganisations = async () => {
        try {
            const response = await axiosInstance.get('user_management/users/organisations/');
            setOrganisations(response.data);
            if (response.data.length > 0) {
                setSelectedOrganisation(response.data[0]); // Wähle das erste Team als ausgewähltes Team aus
                if (response.data[0].teams.length > 0) {
                    setSelectedTeam(response.data[0].teams[0]); // Wähle das erste Team als ausgewähltes Team aus
                } else {
                    setSelectedTeam(null);
                }
                // fetchTeams(response.data[0]);
            }
        } catch (error) {
            console.log('Fehler beim Abrufen der Organisationen: ' , error);
        }
    };
    
    const handleTeamSelect = (Organisation, Team) => {
        setSelectedOrganisation(Organisation);
        setSelectedTeam(Team);
    };

    const handleOffensePlaybookSelect = (OffensePlaybook) => {
        console.log("Offenseplaybook: " + OffensePlaybook);
        setSelectedOffensePlaybook(OffensePlaybook);
    }


    const handleDefensePlaybookSelect = (DefensePlaybook) => {
        setSelectedDefensePlaybook(DefensePlaybook);
    }
    
    const handleSpecialPlaybookSelect = (SpecialPlaybook) => {
        setSelectedSpecialPlaybook(SpecialPlaybook);
    }
    return (
        <SnackbarProvider>
            <ErrorBoundaryWithSnackbar>
                <GlobalProvider>
                    <DashboardLayout 
                        isMobile={isMobile}  >
                                <Routes>
                                    <Route 
                                        path="/" 
                                        element={<Dashboard  />} 
                                    />
                                    <Route
                                        path="/user-management"
                                        element={<UserManagementPage  />}
                                        />
                                    <Route 
                                        path="/:unit/play/:playId"
                                        element={<PlayDetailPage />} />
                                    <Route 
                                        path="/plays/:unit"
                                        element={<PlayTablePage />} />
                                    <Route
                                        path="/playbooks/:unit"
                                        element={<PlaybookPage />}
                                        />
                                    <Route 
                                        path="/terminology/:playbookId"
                                        element={<PlaybookTypesPage />} />
                                    <Route 
                                        path="/playbooks/:unit/:playbookId/tags/:type"
                                        element={<PlaybookTagsPage />} />
                                    <Route 
                                        path="/teams/:teamId/events" 
                                        element={<TeamEventsPage teamId={selectedTeam?.id} />} />
                                    <Route 
                                        path="/events/practice/:practiceId" 
                                        element={<PracticeDetailPage />} />
                                    <Route 
                                        path="/scripts/:scriptId/plays/" 
                                        element={<ScriptPage />} />
                    
                                    <Route path="/practices" element={<PracticeManagement organisationId={selectedOrganisation?.id} teamId={selectedTeam?.id} />} />
                                    <Route path="/practices/:practiceId" element={<LivePracticeTable />} />
                                    <Route path="/create_practice" element={<PracticeForm teamId={selectedTeam?.id} />} />
                                    <Route path="/scripts/:practice_id/:playType" element={<LivePractice />} />
                                </Routes>
                    </DashboardLayout>
                </GlobalProvider>
            </ErrorBoundaryWithSnackbar>
        </SnackbarProvider>
    );
}

export default App;
