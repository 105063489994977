// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

@media (max-width: 600px) {
  .MuiTypography-h5 {
    font-size: 1.2rem;
  }
  .MuiListItem-root {
    padding: 8px;
    font-size: 0.9rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/App.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,UAAA;EACA,iCAAA;AACJ;;AAEE;EACE;IACE,iBAAA;EACJ;EAEE;IACE,YAAA;IACA,iBAAA;EAAJ;AACF","sourcesContent":["body {\n    margin: 0;\n    padding: 0;\n    font-family: 'Roboto', sans-serif;\n  }\n  \n  @media (max-width: 600px) {\n    .MuiTypography-h5 {\n      font-size: 1.2rem; // Kleinere Überschriften\n    }\n  \n    .MuiListItem-root {\n      padding: 8px;\n      font-size: 0.9rem; // Kleinere Menüpunkte\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
