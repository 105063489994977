// Dashboard.js

import React, { useEffect, useState } from 'react';
import { Button, Typography, Container, Paper } from '@mui/material';
import PlayDiagram from './PlayDiagram';
import axiosInstance from './api/axiosInstance';
import Header from './components/Header'

const Dashboard = () => {
    
    return (
      <>
        <Header title="Football Tracker">
        

        </Header>
      </>
    );
}

export default Dashboard;
