import React, { useState, useEffect } from 'react';
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Collapse,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/system';
import { useGlobalContext } from '../contexts/GlobalContext';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledTeamItem = styled(ListItem)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(2),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const DropdownContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%', // Passe die Breite nach Bedarf an
}));

const DropdownMenu = styled('div')(({ theme, isOpen }) => ({
  position: 'absolute',
  top: '100%',
  left: 0,
  width: '100%',
  maxHeight: isOpen ? '300px' : 0, // Maximale Höhe für Scroll-Effekte
  overflowY: 'auto',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  borderRadius: theme.shape.borderRadius,
  zIndex: 10,
  transition: 'max-height 0.3s ease',
}));

function OrganisationDropdown() {
  
  const {
    organisations,
    selectedTeam, 
    selectedOrganisation,
    selectTeam,
    selectOrgTeam,
    selectOrganisation,
  } = useGlobalContext();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [expandedOrganisation, setExpandedOrganisation] = useState(null);


  const handleOrganisationClick = (orgId) => {
    setExpandedOrganisation(expandedOrganisation === orgId ? null : orgId);
  };

  const isSelectedTeam = (team) => selectedTeam && team.id === selectedTeam.id;

  const handleToggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  return (
    <DropdownContainer>
      {/* Button to toggle the dropdown menu */}
      <Button 
        variant="contained" 
        onClick={handleToggleDropdown} 
        style={{ marginBottom: '10px' }}
        fullWidth
      >
        {selectedTeam ? selectedTeam.name : 'Select Team'} {isDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>

      {/* Dropdown Menu */}
      <DropdownMenu isOpen={isDropdownOpen}>
        <List component="nav">
          {organisations.map((org) => (
            <React.Fragment key={org.id}>
              <StyledListItem button onClick={() => handleOrganisationClick(org.id)}>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                      {org.name}
                    </Typography>
                  }
                />
                {expandedOrganisation === org.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </StyledListItem>

              <Collapse in={expandedOrganisation === org.id} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {org.teams.map((team) => (
                    <StyledTeamItem
                      button
                      key={team.id}
                      onClick={() => selectOrgTeam(org, team)}
                      selected={isSelectedTeam(team)}
                    >
                      <ListItemText primary={team.name} />
                      {isSelectedTeam(team) && <CheckCircleIcon color="primary" />}
                    </StyledTeamItem>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      </DropdownMenu>
    </DropdownContainer>
  );
}

export default OrganisationDropdown;
