// Sidebar.js
import React, { useEffect, useState } from 'react';
import axiosInstance from '../api/axiosInstance';
import { Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText, IconButton, Drawer, Box, Typography, Divider, BottomNavigation, BottomNavigationAction } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';  // Beispiel für Icon
import LogoutIcon from '@mui/icons-material/Logout';  
import SettingsIcon from '@mui/icons-material/Settings';  // Beispiel für Icon
import { Link, useLocation, NavLink } from 'react-router-dom';
import OrganisationDropdown from './OrganisationDropdown';
import '../assets/scss/Sidebar.scss';
import { useGlobalContext } from '../contexts/GlobalContext';

const Sidebar = ({ 
  isMobile,
  onLogout }) => {
    
  const {
    organisations,
    selectedTeam, 
    selectedOffensePlaybook, 
    selectedDefensePlaybook, 
    selectedSpecialPlaybook,
    offensePlaybookTypes,
    defensePlaybookTypes,
    specialPlaybookTypes
  } = useGlobalContext();
    const [offenseTypes, setOffenseTypes] = useState([]);
    const [defenseTypes, setDefenseTypes] = useState([]);
    const [specialTypes, setSpecialTypes] = useState([]);
    const [menuItems, setMenuItems] = useState([]);

    const location = useLocation();
    const [expanded, setExpanded] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const toggleDrawer = () => {
      setDrawerOpen(!drawerOpen);
    };

    
    useEffect(() => {
      setMenuItems([
        {
          title: 'Team',
          subItems: [
            { title: 'Team Manager', path: '/user-management' },
            { title: 'Schedule', path: `/teams/${selectedTeam?.id}/events`}
          ]
        },
        {
          title: 'Offense',
          subItems: [
            { title: 'Plays', path: '/plays/Offense' },
            {
              title: 'Playbooks',
              path: '/playbooks/Offense',
              childrens: offensePlaybookTypes.map((item) => ({
                name: item.name,
                id: item.id,
                path: `playbooks/Offense/${selectedOffensePlaybook?.id}/tags/${item.id}`,
              })),
            },
          ],
        },
        {
          title: 'Defense',
          subItems: [
            { title: 'Plays', path: '/plays/Defense' },
            {
              title: 'Playbooks',
              path: '/playbooks/Defense',
              childrens: defensePlaybookTypes.map((item) => ({
                name: item.name,
                id: item.id,
                path: `playbooks/Defense/${selectedDefensePlaybook?.id}/tags/${item.id}`,
              })),
            },
          ],
        },
        {
          title: 'Special Teams',
          subItems: [
            { title: 'Plays', path: '/plays/Special' },
            {
              title: 'Playbooks',
              path: '/playbooks/Special',
              childrens: specialPlaybookTypes.map((item) => ({
                name: item.name,
                id: item.id,
                path: `playbooks/Special/${selectedSpecialPlaybook?.id}/tags/${item.id}`, 
              })),
            },
          ],
        },
      ]);
    }, [offensePlaybookTypes, defensePlaybookTypes, specialPlaybookTypes]);

    const SidebarContent = () => (
      <Box sx={{ padding: 2 }}>
        <Typography variant="h5" sx={{ color: 'white', padding: 2 }}>
          Football Tracker
        </Typography>
        <OrganisationDropdown />
        <Divider sx={{ marginBottom: '0.5rem' }} />
        {/* Dynamisches Menü */}
        <List>
          {menuItems.map((item, index) => (
            <Accordion
              key={index}
              expanded={expanded === item.title}
              onChange={handleAccordionChange(item.title)}
              sx={{
                backgroundColor: 'primary.main',
                color: 'white',
                boxShadow: 'none',
                margin: 0,  
                padding: '0rem 0.5rem',
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
                <Typography>{item.title}</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  margin: 0,
                  padding: 0, // Reduziert das Padding innerhalb der Details
                }}>
                <List
                  sx={{
                    margin: 0,
                    padding: 0, // Reduziert das Padding innerhalb der Details
                  }}>
                  {item.subItems.map((subItem, subIndex) => (
                    <React.Fragment key={subIndex}>
                      {/* Haupt-Menüpunkte */}
                      <ListItem
                        component={NavLink}
                        to={subItem.path}
                        sx={{
                          padding: '0.5rem 1.5rem',
                          color: 'white',
                          transition: 'background-color 0.3s, color 0.3s', // Sanfter Übergang
                          '&.active': {
                            backgroundColor: 'background.default', // Aktive Hintergrundfarbe
                            color: 'primary.main', // Aktive Textfarbe
                            fontWeight: 'bold',
                          },
                          '&:hover': {
                            backgroundColor: 'background.default', // Hover-Hintergrundfarbe
                            color: 'primary.main', // Hover-Textfarbe
                          },
                        }}
                      >
                        {subItem.title}
                      </ListItem>

                      {/* Kind-Elemente (childrens) */}
                      {subItem.childrens &&
                        subItem.childrens.map((child, childIndex) => (
                          <ListItem
                            key={childIndex}
                            component={NavLink}
                            to={child.path}
                            sx={{
                              padding: '0.5rem 2rem',
                              color: 'white',
                              transition: 'background-color 0.3s, color 0.3s', // Sanfter Übergang
                              '&.active': {
                                backgroundColor: 'background.default', // Aktive Hintergrundfarbe
                                color: 'primary.main', // Aktive Textfarbe
                                fontWeight: 'bold',
                              },
                              '&:hover': {
                                backgroundColor: 'background.default', // Hover-Hintergrundfarbe
                                color: 'primary.main', // Hover-Textfarbe
                              },
                            }}
                          >
                            {child.name}
                          </ListItem>
                        ))}
                    </React.Fragment>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </List>
      </Box>
    );


      return (
        <>
            {/* Mobile: Hamburger-Menü */}
            {isMobile && (
              <Box
                sx={{
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  backgroundColor: 'primary.main',
                  height: '60px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '0 16px',
                }}
              >
                <IconButton
                    onClick={toggleDrawer}
                    sx={{ color: 'white' }}
                >
                    <MenuIcon />
                </IconButton>
                {/* Logo in der Mitte */}
                <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold' }}>
                  <img src="/logo.png" alt="FT" style={{ height: '30px' }} />
                </Typography>

                {/* Home-Button rechts */}
                <IconButton 
                  sx={{ color: 'white' }} 
                  component={NavLink}
                  to="/"
                >
                  <HomeIcon/>
                </IconButton >
                {/* Logout-Button */}
                <IconButton onClick={onLogout} sx={{ color: 'white' }}>
                  <LogoutIcon />
                </IconButton>
              </Box>
            )}
            {isMobile ? (
                <Drawer
                    anchor="left"
                    open={drawerOpen}
                    onClose={toggleDrawer}
                    PaperProps={{ sx: { width: '70%', backgroundColor: 'primary.main' } }}
                >
                    <IconButton onClick={toggleDrawer} sx={{ alignSelf: 'flex-end', padding: 2 }}>
                        <CloseIcon />
                    </IconButton>
                    {SidebarContent()}
                </Drawer>
            ) : (
              <>
              <IconButton
                onClick={onLogout}
                sx={{
                  position: 'fixed',
                  top: 16,
                  right: 16,
                  color: 'white',
                  backgroundColor: 'primary.main',
                  borderRadius: '50%',
                  '&:hover': { backgroundColor: 'primary.light' },
                }}
              >
                <LogoutIcon />
              </IconButton>
              <Box
                sx={{
                  width: { xs: '60px', sm: '200px', md: '250px', lg: '280px', xl: '300px' }, // Einheitliche Breite für alle Breakpoints
                  backgroundColor: 'primary.main',
                  minHeight: '100vh',
                  position: 'fixed', // Fixierte Sidebar
                }}
              >
                
                {SidebarContent()}
              </Box>
              </>
            )}
        </>
      );
};

export default Sidebar;
