import React, { useState } from 'react';
import { Box, CssBaseline } from '@mui/material';
import Sidebar from './Sidebar';
import Header from './Header';
import ContainerDashboard from './ContainerDashboard';

const DashboardLayout = ({ children, organisations, selectedOrganisation, selectedTeam, handleTeamSelect, selectedOffensePlaybook, selectedDefensePlaybook, selectedSpecialPlaybook, isMobile }) => {
  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <CssBaseline />
        <Sidebar
            isMobile={isMobile} // Mobile-Flag übergeben
        />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: isMobile ? 2 : 3,
          backgroundColor: '#fff',
          marginLeft: { xs: 0, sm: '200px', md: '250px', lg: '280px', xl: '300px' },
          paddingBottom: isMobile ? '70px' : 0, 
        }}
      >
        <ContainerDashboard>
            {children}
        </ContainerDashboard>

      </Box>
    </Box>
  );
};

export default DashboardLayout;
