// UserManagement.js
import React, { useEffect, useState } from 'react';
import axiosInstance from '../api/axiosInstance';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Chip, IconButton, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel'; // Import des X Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContainerPageHeader from '../components/Header';
import { useGlobalContext } from '../contexts/GlobalContext';

function UserManagementPage({ organisationId }) {
  const {
    organisations,
    selectedOrganisation,
    selectedTeam,
  } = useGlobalContext();
  const [users, setUsers] = useState([]);
  const [positions, setPositions] = useState([]); // Liste der verfügbaren Positionen
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isPositionDialogOpen, setIsPositionDialogOpen] = useState(false); // Dialog für Position hinzufügen
  const [isMembershipDialogOpen, setIsMembershipDialogOpen] = useState(false); // Dialog für Membership hinzufügen
  const [selectedUserTeam, setSelectedUserTeam] = useState(selectedTeam);
  const [currentUser, setCurrentUser] = useState({
    id: null,
    email: '',
    username: '',
    first_name: '',
    last_name: '',
    positions: [],
    memberships: []
  });
  const [selectedPosition, setSelectedPosition] = useState(''); // Die ausgewählte Position
  const [selectedRole, setSelectedRole] = useState(''); // Die ausgewählte Rolle
  const [roles] = useState(['Coach', 'Athlete', 'Staff']); // Mögliche Rollen

  useEffect(() => {
    fetchUsers();
    fetchPositions();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/user_management/organisations/${selectedOrganisation?.id}/users/`);
      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      setError('Failed to fetch users.');
      setLoading(false);
    }
  };

  const fetchPositions = async () => {
    try {
      const response = await axiosInstance.get(`/user_management/positions/`); // API-Endpoint für Positionen
      setPositions(response.data);
    } catch (error) {
      setError('Failed to fetch positions.');
    }
  };
  // Öffne Dialog zum Hinzufügen einer Position zu einem bestimmten Benutzer
  const handleAddPositionClick = (user) => {
    setCurrentUser(user); // Speichere den aktuellen Benutzer, dem die Position hinzugefügt wird
    setIsPositionDialogOpen(true); // Öffne das Positionen-Dialog
  };

  // Schließen des Dialogs zum Hinzufügen einer Position
  const handleClosePositionDialog = () => {
    setIsPositionDialogOpen(false);
    setSelectedPosition('');
  };

  const handleOpenDialog = (user = {
    id: null,
    email: '',
    username: '',
    first_name: '',
    last_name: '',
    positions: []
  }) => {
    setCurrentUser(user);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setCurrentUser({
      id: null,
      email: '',
      username: '',
      first_name: '',
      last_name: '',
      positions: []
    });
  };

  const handleSaveUser = async () => {
    try {

      const userPayload = {
        email: currentUser.email,
        username: currentUser.username,
        first_name: currentUser.first_name,
        last_name: currentUser.last_name,
        organisations: [ selectedOrganisation.id ],
        positions: currentUser.positions.map(pos => pos.id), // IDs der Positionen
        memberships: selectedUserTeam && selectedRole ? [{
          team: { id: selectedUserTeam },
          role: selectedRole
        }] : []
      };
      console.log(currentUser);
      if (currentUser.id) {
        // Update existing user
        await axiosInstance.put(`/user_management/users/${currentUser.id}/update/`, userPayload);
      } else {
        // Create new user
        await axiosInstance.post(`/user_management/users/create/`, userPayload);
      }
      fetchUsers();
      handleCloseDialog();
    } catch (error) {
      setError('Failed to save user.');
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await axiosInstance.delete(`/user_management/users/${userId}/`);
      fetchUsers();
    } catch (error) {
      setError('Failed to delete user.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleSavePosition = async () => {
    if (selectedPosition && currentUser) {
      try {
        // API-Aufruf zur Zuweisung der Position an den Benutzer
        await axiosInstance.post(`/user_management/users/${currentUser.id}/assign-position/${selectedPosition}/`);

        // Aktualisiere die Benutzerliste nach erfolgreichem Hinzufügen der Position
        const updatedUsers = users.map(user => {
          if (user.id === currentUser.id) {
            return {
              ...user,
              positions: [...user.positions, positions.find(pos => pos.id === selectedPosition)] // Füge die ausgewählte Position hinzu
            };
          }
          return user;
        });
        setUsers(updatedUsers);
        handleClosePositionDialog(); // Schließe den Dialog nach dem Hinzufügen
      } catch (error) {
        console.error('Failed to assign position:', error);
        setError('Failed to assign position.');
      }
    }
  };

  // Entferne eine Position vom Benutzer
  const handleRemovePosition = async (userId, positionId) => {
    try {
      // API-Aufruf zum Entfernen der Position
      await axiosInstance.delete(`/user_management/users/${userId}/assign-position/${positionId}/`);

      // Aktualisiere die Benutzerliste nach erfolgreichem Entfernen der Position
      const updatedUsers = users.map(user => {
        if (user.id === userId) {
          return {
            ...user,
            positions: user.positions.filter(position => position.id !== positionId) // Entferne die Position aus der Liste
          };
        }
        return user;
      });
      setUsers(updatedUsers);
    } catch (error) {
      console.error('Failed to remove position:', error);
      setError('Failed to remove position.');
    }
  };

  // Schließen des Dialogs zum Hinzufügen einer Membership
  const handleCloseMembershipDialog = () => {
    setIsMembershipDialogOpen(false);
    setSelectedRole('');
  };

  // Öffne Dialog zum Hinzufügen einer Membership zu einem bestimmten Benutzer
  const handleAddMembershipClick = (user) => {
    setCurrentUser(user); // Speichere den aktuellen Benutzer, dem die Membership hinzugefügt wird
    setIsMembershipDialogOpen(true); // Öffne das Membership-Dialog
  };

  // Speichere die ausgewählte Membership und sende sie an die API
  const handleSaveMembership = async () => {
    if (selectedUserTeam && selectedRole && currentUser) {
      try {
        // API-Aufruf zur Zuweisung der Membership an den Benutzer
        await axiosInstance.post(`/user_management/users/${currentUser.id}/membership/`, {
          team: selectedUserTeam,
          role: selectedRole,
        });

        // Aktualisiere die Benutzerliste nach erfolgreichem Hinzufügen der Membership
        const updatedUsers = users.map(user => {
          if (user.id === currentUser.id) {
            return {
              ...user,
              memberships: [
                ...user.memberships,
                { team: selectedOrganisation.teams.find(team => team.id === selectedUserTeam), role: selectedRole }
              ] // Füge die ausgewählte Membership hinzu
            };
          }
          return user;
        });
        setUsers(updatedUsers);
        handleCloseMembershipDialog(); // Schließe den Dialog nach dem Hinzufügen
      } catch (error) {
        console.error('Failed to assign membership:', error);
        setError('Failed to assign membership.');
      }
    }
  };

  // Entferne eine Membership vom Benutzer
  const handleRemoveMembership = async (userId, membershipId) => {
    try {
      // API-Aufruf zum Entfernen der Membership
      await axiosInstance.delete(`/user_management/memberships/${membershipId}/`);

      // Aktualisiere die Benutzerliste nach erfolgreichem Entfernen der Membership
      const updatedUsers = users.map(user => {
        if (user.id === userId) {
          return {
            ...user,
            memberships: user.memberships.filter(membership => membership.id !== membershipId) // Entferne die Membership aus der Liste
          };
        }
        return user;
      });
      setUsers(updatedUsers);
    } catch (error) {
      console.error('Failed to remove membership:', error);
      setError('Failed to remove membership.');
    }
  };

  if (loading) return <p>Loading users...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      <ContainerPageHeader title="Team Manager">
        <Button variant="contained" color="primary" onClick={() => handleOpenDialog()}>
          Add User
        </Button>
      </ContainerPageHeader>

      <TableContainer>
        <Table stickyHeader >
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Benutzername</TableCell>
              <TableCell>Vorname</TableCell>
              <TableCell>Nachname</TableCell>
              <TableCell>Positions</TableCell>
              <TableCell>Rollen</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.first_name}</TableCell>
                <TableCell>{user.last_name}</TableCell>
                <TableCell>
                  {user.positions.map((position, index) => (
                    <Chip
                      key={index}
                      label={position.name}
                      style={{ margin: '4px' }}
                      onDelete={() => handleRemovePosition(user.id, position.id)} // Entfernen der Position beim Klick auf das X
                      deleteIcon={<CancelIcon />} // X Icon für das Entfernen
                    />
                  ))}
                  <IconButton onClick={() => handleAddPositionClick(user)}>
                    <AddIcon /> {/* Add Position Icon */}
                  </IconButton>
                </TableCell>
                <TableCell>
                  {user.memberships.map((membership, index) => (
                    <Chip
                      key={index}
                      label={`${membership.team.name} (${membership.role})`}
                      style={{ margin: '4px' }}
                      onDelete={() => handleRemoveMembership(user.id, membership.id)} // Entfernen der Membership
                      deleteIcon={<CancelIcon />} // X Icon für das Entfernen
                    />
                  ))}
                  {/* Add Icon for Membership */}
                  <IconButton onClick={() => handleAddMembershipClick(user)}>
                    <AddIcon /> {/* Add Membership Icon */}
                  </IconButton>
                </TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleOpenDialog(user)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteUser(user.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{currentUser.id ? 'Edit User' : 'Add User'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Email"
            name="email"
            value={currentUser.email}
            onChange={handleChange}
            fullWidth
            variant="outlined"
          />
          <TextField
            margin="dense"
            label="Username"
            name="username"
            value={currentUser.username}
            onChange={handleChange}
            fullWidth
            variant="outlined"
          />
          <TextField
            margin="dense"
            label="First Name"
            name="first_name"
            value={currentUser.first_name}
            onChange={handleChange}
            fullWidth
            variant="outlined"
          />
          <TextField
            margin="dense"
            label="Last Name"
            name="last_name"
            value={currentUser.last_name}
            onChange={handleChange}
            fullWidth
            variant="outlined"
          />
          {/* Team auswählen */}
          <FormControl fullWidth margin="normal">
            <InputLabel>Team</InputLabel>
            <Select
              value={selectedUserTeam}
              onChange={(e) => setSelectedUserTeam(e.target.value)}
            >
              {selectedOrganisation?.teams.map((team) => (
                <MenuItem key={team.id} value={team.id}>
                  {team.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Rolle auswählen */}
          <FormControl fullWidth margin="normal">
            <InputLabel>Rolle</InputLabel>
            <Select
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
            >
              {roles.map((role, index) => (
                <MenuItem key={index} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveUser} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Dialog zum Hinzufügen einer Position */}
      <Dialog open={isPositionDialogOpen} onClose={handleClosePositionDialog}>
        <DialogTitle>Add Position to {currentUser?.username}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Position</InputLabel>
            <Select
              value={selectedPosition}
              onChange={(e) => setSelectedPosition(e.target.value)}
            >
              {positions.map((position) => (
                <MenuItem key={position.id} value={position.id}>
                  {position.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePositionDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSavePosition} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog zum Hinzufügen einer Membership */}
      <Dialog open={isMembershipDialogOpen} onClose={handleCloseMembershipDialog}>
        <DialogTitle>Add Membership to {currentUser?.username}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Team</InputLabel>
            <Select
              value={selectedUserTeam}
              onChange={(e) => setSelectedUserTeam(e.target.value)}
            >
              {selectedOrganisation.teams.map((team) => (
                <MenuItem key={team.id} value={team.id}>
                  {team.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Role</InputLabel>
            <Select
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
            >
              {roles.map((role, index) => (
                <MenuItem key={index} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMembershipDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveMembership} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UserManagementPage;
