import React from 'react';
import { Box, Paper, Typography, Grid } from '@mui/material';

const ContainerDashboard = ({ children }) => {
    /*return (
          <Paper 
            elevation={3} 
            sx={{ 
              height: '100%', 
              borderRadius: '16px', 
              display: 'flex',
              padding: 1,
              backgroundColor: '#fff', }}
          >
            <Box sx={{ width: '100%', height: '100%', padding: 2 }}>
              { children }
            </Box>
             
          </Paper>
    );*/
    return (
      <Box // sx={{ padding: 3 }}
      >
        <Grid container >
          {children}
        </Grid>
      </Box>
    );
};

export default ContainerDashboard;