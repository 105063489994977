// PlaybookTypesPage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Typography, 
  Box, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  IconButton, 
  Button, 
  Modal, 
  TextField,
  InputAdornment,
  Checkbox,
  FormControlLabel 
} from '@mui/material';
import { Edit, Delete, Search } from '@mui/icons-material';
import axiosInstance from '../api/axiosInstance';
import AddIcon from '@mui/icons-material/Add';
import ContainerPageHeader from '../components/Header';
import { useGlobalContext } from '../contexts/GlobalContext';

const PlaybookTypesPage = () => {
    const { playbookId } = useParams(); 
    const { 
      offensePlaybooks, 
      defensePlaybooks, 
      specialPlaybooks 
    } = useGlobalContext(); // Playbooks aus dem Context holen

    // Finde das Playbook anhand der ID in allen Units
    const playbook = [...offensePlaybooks, ...defensePlaybooks, ...specialPlaybooks]
      .find(pb => pb.id.toString() === playbookId);
    const [playbookTypes, setPlaybookTypes] = useState([]);
    const [filteredTypes, setFilteredTypes] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [open, setOpen] = useState(false);
    const [newTypeName, setNewTypeName] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [isDefault, setIsDefault] = useState(false);
    const [showInMenu, setShowInMenu] = useState(false);

    // Laden der PlaybookTypes
    useEffect(() => {
        fetchPlaybookTypes();
    }, []);

    const fetchPlaybookTypes = async () => {
        try {
        const response = await axiosInstance.get(`/playbook_management/${playbookId}/playbook-types/?unit=${playbook.unit}`);
        setPlaybookTypes(response.data);
        setFilteredTypes(response.data);
        } catch (error) {
        console.error('Error fetching playbook types:', error);
        }
    };

    const handleEdit = (type) => {
      setSelectedType(type);
      setNewTypeName(type.name);
      setIsDefault(type.default);
      setShowInMenu(type.show_in_menu);
      setOpen(true);
    };

    const handleDelete = async (id) => {
        try {
        await axiosInstance.delete(`/playbook_management/${playbookId}/playbook-types/${id}/`);
        fetchPlaybookTypes(); // Tabelle neu laden
        } catch (error) {
        console.error('Error deleting playbook type:', error);
        }
    };

    const handleSave = async () => {
        try {
          const playbookTypeData = {
            name: newTypeName,
            default: isDefault,
            show_in_menu: showInMenu,
            playbook: playbookId,
            active: true,
            global_type: false,
            unit: playbook.unit
          };
          if (selectedType) {
            // Update bestehender Playbook Type
            await axiosInstance.put(`/playbook_management/${playbookId}/playbook-types/${selectedType.id}/`, playbookTypeData);
          } else {
            // Erstellen eines neuen Playbook Types
            await axiosInstance.post(`/playbook_management/${playbookId}/playbook-types/`, playbookTypeData);
          }
    
          setOpen(false);
          fetchPlaybookTypes(); // Tabelle neu laden
          setNewTypeName('');
          setIsDefault(false);
          setShowInMenu(false);
          setSelectedType(null);
        } catch (error) {
          console.error('Error saving playbook type:', error);
        }
    };

    const handleSearch = (event) => {
      const value = event.target.value.toLowerCase();
      setSearchQuery(value);
      if (value === '') {
        setFilteredTypes(playbookTypes);
      } else {
        setFilteredTypes(playbookTypes.filter(type =>
          type.name.toLowerCase().includes(value)
        ));
      }
    };

    return (
        <>
          {/* Header */}
          <ContainerPageHeader title={`${playbook?.name} Terminology`}>
            <TextField
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              size="small"
              sx={{ marginRight: 2 }}
            />
            <Button startIcon={<AddIcon />}  variant="contained" color="primary" onClick={() => setOpen(true)}>
              Add New Type
            </Button>
          </ContainerPageHeader>

          {/* Tabelle */}
          <TableContainer>
            <Table stickyHeader aria-label="playbook types table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTypes.map((type) => (
                  <TableRow key={type.id} hover role="checkbox" tabIndex={-1}>
                    <TableCell>{type.name}</TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => handleEdit(type)} color="primary">
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(type.id)} color="secondary">
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Modal für Erstellen/Bearbeiten */}
          <Modal open={open} onClose={() => setOpen(false)}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                backgroundColor: 'white',
                padding: 4,
                boxShadow: 24,
                borderRadius: 2
              }}
            >
              <Typography variant="h6" guqtterBottom>
                {selectedType ? 'Edit Playbook Type' : 'Add New Playbook Type'}
              </Typography>
              <TextField
                fullWidth
                label="Name"
                value={newTypeName}
                onChange={(e) => setNewTypeName(e.target.value)}
                sx={{ marginBottom: 2 }}
              />
              <FormControlLabel
                fullWidth
                control={
                  <Checkbox
                    checked={isDefault}
                    onChange={(e) => setIsDefault(e.target.checked)}
                  />
                }
                label="Make Default? This will display this as a field on play pages"
              />
              <FormControlLabel
                fullWidth
                control={
                  <Checkbox
                    checked={showInMenu}
                    onChange={(e) => setShowInMenu(e.target.checked)}
                  />
                }
                label="Show in menu? This will display this in your side navigation"
              />
              <Button variant="contained" color="primary" onClick={handleSave}>
                {selectedType ? 'Update' : 'Create'}
              </Button>
            </Box>
          </Modal>
        </>
    );
};

export default PlaybookTypesPage;
