// PlayTable.js
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, FormControl, InputLabel, Select, MenuItem,
  Paper, TablePagination, TextField, InputAdornment, IconButton, TableSortLabel,
  Button, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import axiosInstance from '../api/axiosInstance';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContainerPageHeader from '../components/Header';
import { useGlobalContext } from '../contexts/GlobalContext';
// TODO accordian entweder nach Playbook Gruppieren oder nach Types 

const PlayTablePage = ( ) => {
  const { unit } = useParams();
  const { 
    offensePlaybooks, defensePlaybooks, specialPlaybooks,
    selectedOffensePlaybook, selectedDefensePlaybook, selectedSpecialPlaybook,
    setSelectedOffensePlaybook, setSelectedDefensePlaybook, setSelectedSpecialPlaybook,
    offensePlaybookTypes,
    defensePlaybookTypes,
    specialPlaybookTypes,
  } = useGlobalContext();

  // Playbooks basierend auf der Unit abrufen
  const playbooks = unit === 'Offense' ? offensePlaybooks : 
                    unit === 'Defense' ? defensePlaybooks : 
                    specialPlaybooks;

  const selectedPlaybook = unit === 'Offense' ? selectedOffensePlaybook : 
                           unit === 'Defense' ? selectedDefensePlaybook : 
                           selectedSpecialPlaybook;

  const setSelectedPlaybook = unit === 'Offense' ? setSelectedOffensePlaybook : 
                              unit === 'Defense' ? setSelectedDefensePlaybook : 
                              setSelectedSpecialPlaybook;
  const playbookTypes = unit === 'Offense' ? offensePlaybookTypes : 
                              unit === 'Defense' ? defensePlaybookTypes : 
                              specialPlaybookTypes;
  const [playsByPlaybook, setPlaysByPlaybook] = useState({});
  const [filteredPlaysByPlaybook, setFilteredPlaysByPlaybook] = useState({});
  const [groupedPlaysByTag, setGroupedPlaysByTag] = useState({});
  const [filteredGroupedPlaysByTag, setFilteredGroupedPlaysByTag] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');

  const [groupBy, setGroupBy] = useState('Playbook'); // 'playbook' oder 'playtype'

  const [openDialog, setOpenDialog] = useState(false);
  const [playToDelete, setPlayToDelete] = useState(null);

  useEffect(() => {
    fetchAllPlaybooksData();
  }, [unit, playbooks, groupBy]);
  
  const fetchAllPlaybooksData = async () => {
    if (playbooks.length === 0) return;

    const playsData = {};

    await Promise.all(
      playbooks.map(async (playbook) => {
        try {
          const [playsResponse, typesResponse] = await Promise.all([
            axiosInstance.get(`/playbook_management/${playbook.id}/plays/?unit=${playbook.unit}`),
          ]);

          playsData[playbook.id] = playsResponse.data;
          
        } catch (error) {
          console.error(`Error fetching data for playbook ${playbook.name}:`, error);
        }
      })
    );

    setPlaysByPlaybook(playsData);
    setFilteredPlaysByPlaybook(playsData);
    if (groupBy !== "Playbook") {
      setGroupedPlaysByTag(groupPlaysByType(Object.values(playsData).flat(), groupBy));
      setFilteredGroupedPlaysByTag(groupPlaysByType(Object.values(playsData).flat(), groupBy));
    }
  };


  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchQuery(value);
  };
/*
  const handleSortRequest = (property) => {
    const isAscending = orderBy === property && order === 'asc';
    setOrder(isAscending ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...filteredPlays].sort((a, b) => {
      if (a[property] < b[property]) {
        return order === 'asc' ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return order === 'asc' ? 1 : -1;
      }
      return 0;
    });

    setFilteredPlays(sortedData);
  };*/

  // 🔎 **Filtern der Plays**
  // 🔎 Sichere Filter-Funktion
  const filterPlays = (plays = []) => {
    if (!Array.isArray(plays)) return []; // Falls `plays` nicht existiert, gib leeres Array zurück
    if (!searchQuery.trim()) return plays;     

    return plays.filter((play) => {
      const playNameMatch = play.name?.toLowerCase().includes(searchQuery);
      const tagMatch = play.playbook_type_tags?.some(typeTag =>
        typeTag.tags_output?.some(tag => tag.name.toLowerCase().includes(searchQuery))
      );
      return playNameMatch || tagMatch;
    });
  };

  // 🔼 **Sortieren der Plays**
  const handleSortRequest = (property, playbookId) => {
    const isAscending = orderBy === property && order === 'asc';
    setOrder(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
    const playsByplaybookMask = playsByPlaybook; 
    const sortedData = [...playsByplaybookMask[playbookId]].sort((a, b) => {
      let aValue = a[property] || ''; // Falls Wert nicht existiert, leeren String setzen
      let bValue = b[property] || '';


      // Falls die Spalte ein Datum ist
      if (property.includes('date') || property.includes('updated_at')) {
        aValue = new Date(aValue).getTime() || 0;
        bValue = new Date(bValue).getTime() || 0;
      }
      if (property === 'playbook') {
        aValue = a.playbook?.name || '';
        bValue = b.playbook?.name || '';
      } else if (aValue === '' && bValue === '') {
        aValue = a.playbook_type_tags.find(
          (tag) => tag.playbook_type_name === property
        )?.tags_output[0].name || '';
        bValue = b.playbook_type_tags.find(
          (tag) => tag.playbook_type_name === property
        )?.tags_output[0].name || '';
      }
      

      // Falls es sich um Strings handelt
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return isAscending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }

      return isAscending ? aValue - bValue : bValue - aValue;
    });
    playsByplaybookMask[playbookId] = sortedData;

    setFilteredPlaysByPlaybook(playsByplaybookMask); // Die sortierte Liste in den State setzen
  };

  const handleSortRequestByTags = (property, tagName) => {
    const isAscending = orderBy === property && order === 'asc';
    setOrder(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
    const groupedPlaysByTagNameMask = groupedPlaysByTag; 
    const sortedData = [...groupedPlaysByTagNameMask[tagName]].sort((a, b) => {
      let aValue = a[property] || ''; // Falls Wert nicht existiert, leeren String setzen
      let bValue = b[property] || '';


      // Falls die Spalte ein Datum ist
      if (property.includes('date') || property.includes('updated_at')) {
        aValue = new Date(aValue).getTime() || 0;
        bValue = new Date(bValue).getTime() || 0;
      }
      if (property === 'playbook') {
        aValue = a.playbook?.name || '';
        bValue = b.playbook?.name || '';
      } else if (aValue === '' && bValue === '') {
        aValue = a.playbook_type_tags.find(
          (tag) => tag.playbook_type_name === property
        )?.tags_output[0].name || '';
        bValue = b.playbook_type_tags.find(
          (tag) => tag.playbook_type_name === property
        )?.tags_output[0].name || '';
      }
      

      // Falls es sich um Strings handelt
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return isAscending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }

      return isAscending ? aValue - bValue : bValue - aValue;
    });
    groupedPlaysByTagNameMask[tagName] = sortedData;

    setFilteredGroupedPlaysByTag(groupedPlaysByTagNameMask); // Die sortierte Liste in den State setzen
  };

  const handleDeleteClick = (play) => {
    setPlayToDelete(play);
    setOpenDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axiosInstance.delete(`/playbook_management/${playToDelete.playbook}/plays/${playToDelete.id}/`);
      setPlaysByPlaybook((prevPlays) => {
        const updatedPlays = { ...prevPlays };
        updatedPlays[playToDelete.playbook] = updatedPlays[playToDelete.playbook].filter((p) => p.id !== playToDelete.id);
        return updatedPlays;
      });
      setOpenDialog(false);
    } catch (error) {
      console.error("Error deleting play:", error);
    }
  };

  const handleAccordionChange = (playbookId) => (event, isExpanded) => {
    setExpanded(isExpanded ? playbookId : false);
  };

  const groupPlaysByType = (plays, selectedPlayType) => {
    const groupedPlays = {};
  
    plays.forEach((play) => {
      const typeTag = play.playbook_type_tags.find(
        (tag) => tag.playbook_type_name === selectedPlayType
      );
  
      if (typeTag && typeTag.tags_output.length > 0) {
        typeTag.tags_output.forEach((tag) => {
          if (!groupedPlays[tag.name]) {
            groupedPlays[tag.name] = [];
          }
          groupedPlays[tag.name].push(play);
        });
      } else {
        if (!groupedPlays["Ohne Tag"]) {
          groupedPlays["Ohne Tag"] = [];
        }
        groupedPlays["Ohne Tag"].push(play);
      }
    });
  
    return groupedPlays;
  };
  
  return (
    <>
      <ContainerPageHeader title={`${unit} Plays`}>
        <FormControl variant="outlined" sx={{ minWidth: 150, ml: 2 }}>
          <InputLabel>Group By</InputLabel>
          <Select
            value={groupBy}
            onChange={(e) => setGroupBy(e.target.value)}
            label="Group By"
          >
            <MenuItem key="menu-playbook" value="Playbook">Playbook</MenuItem>
            { playbookTypes?.map((type) => (
              type.show_in_menu ?
                <MenuItem key={`menu-${type.id}`} value={type.name}>{type.name}</MenuItem>
                : <></>
            ))
          }
            
          </Select>
        </FormControl>
        <TextField
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="outlined"
          size="small"
        />
        <Button
          component={Link}
          to={`/${unit}/play/new`} // Beispiel für eine Offense-Unit, könnte dynamisch gemacht werden
          variant="contained"
          color="primary"
          sx={{ marginBottom: 2 }}
        >
          Add New {unit} Play
        </Button>
      </ContainerPageHeader>
      {/* Gruppierung nach Playbook */}
      {groupBy === "Playbook" && playbooks.map((playbook) => (
        <Accordion key={playbook.id} expanded={expanded === playbook.id} onChange={handleAccordionChange(playbook.id)} sx={{ width: "100%" }}> 
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{playbook.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell >
                      <TableSortLabel active={ orderBy === 'name' } direction={order} onClick={() => handleSortRequest('name', playbook.id)} >
                        Play Name
                      </TableSortLabel>
                    </TableCell>
                    {playbookTypes?.map((typeTag, index) => (
                      <TableCell key={`${playbook.id}-${typeTag.id}`}>
                        <TableSortLabel active={ orderBy === typeTag.name } direction={order} onClick={() => handleSortRequest(typeTag.name, playbook.id)}>{typeTag.name}</TableSortLabel> 
                      </TableCell>
                    )) }
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterPlays(filteredPlaysByPlaybook[playbook.id] || []).map((play, index) => (
                    <TableRow key={`${playbook.id}-${play.id}`}>
                      <TableCell>{play.name}</TableCell>
                      {playbookTypes.map((column) => {
                        const typeTag = play.playbook_type_tags.find(
                          (tag) => tag.playbook_type_name === column.name
                        );
                        return (
                          <TableCell key={`${play.id}-${column.id}`}>
                            {typeTag && typeTag.tags_output.length > 0
                              ? typeTag.tags_output.map((tag) => tag.name).join(', ')
                              : <span style={{ color: '#aaa' }}>Keine Tags</span>}
                          </TableCell>
                        );
                      })}
                      <TableCell>
                        <IconButton component={Link} to={`/${unit}/play/${play.id}`}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteClick(play)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ))}

      {/* Gruppierung nach PlayType */}
      {groupBy !== "Playbook" && Object.keys(groupedPlaysByTag).map((tagName) => (
        <Accordion key={tagName} sx={{ width: "100%" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{tagName}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sortDirection={orderBy === 'name' ? order : false}>
                      <TableSortLabel
                        active={orderBy === 'name'}
                        direction={order}
                        onClick={() => handleSortRequestByTags('name', tagName)}
                      >
                        Play Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sortDirection={orderBy === 'playbook' ? order : false}>
                      <TableSortLabel
                        active={orderBy === 'playbook'}
                        direction={order}
                        onClick={() => handleSortRequestByTags('playbook', tagName)}
                      >
                        Playbook
                      </TableSortLabel>
                      </TableCell>
                    {playbookTypes.map((type) => (
                      <TableCell key={type.id}>
                        <TableSortLabel
                        active={orderBy === type.name}
                        direction={order}
                        onClick={() => handleSortRequestByTags(type.name, tagName)}
                        >
                          {type.name}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterPlays(filteredGroupedPlaysByTag[tagName] || []).map((play, index) => (
                    <TableRow key={index}>
                      <TableCell>{play.name}</TableCell>
                      <TableCell>{play.playbook.name}</TableCell>
                      {playbookTypes.map((type) => {
                        const typeTag = play.playbook_type_tags.find(tag => tag.playbook_type_name === type.name);
                        return (
                          <TableCell key={type.id}>
                            {typeTag && typeTag.tags_output.length > 0
                              ? typeTag.tags_output.map(tag => tag.name).join(', ')
                              : <span style={{ color: '#aaa' }}>Keine Tags</span>}
                          </TableCell>
                        );
                      })}
                      <TableCell>
                        <IconButton component={Link} to={`/${unit}/play/${play.id}`}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteClick(play)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ))}
      {/* Confirm Delete Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the play "{playToDelete?.name}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PlayTablePage;
