import React, { createContext, useState, useContext, useEffect } from 'react';
import axiosInstance from '../api/axiosInstance';

const PlaybookContext = createContext();

export const PlaybookProvider = ({ children, organisationId, teamId }) => {
  const [offensePlaybooks, setOffensePlaybooks] = useState([]);
  const [defensePlaybooks, setDefensePlaybooks] = useState([]);
  const [specialPlaybooks, setSpecialPlaybooks] = useState([]);

  const [selectedOffensePlaybook, setSelectedOffensePlaybook] = useState(null);
  const [selectedDefensePlaybook, setSelectedDefensePlaybook] = useState(null);
  const [selectedSpecialPlaybook, setSelectedSpecialPlaybook] = useState(null);

  // Playbooks für eine bestimmte Unit holen
  const fetchPlaybooks = async (organisationId, teamId) => {
    if (!organisationId || !teamId) return;

    const fetchByUnit = async (unit, setPlaybooks, setSelectedPlaybook) => {
      try {
        setPlaybooks([]);
        const response = await axiosInstance.get(
          `/playbook_management/${organisationId}/${teamId}/playbooks/?unit=${unit}`
        );
        const playbooks = response.data;
        setPlaybooks(playbooks);

        // Automatische Auswahl des ersten Playbooks
        if (playbooks.length > 0) {
          setSelectedPlaybook(playbooks[0]);
        } else {
          setSelectedPlaybook([]);
        }
      } catch (error) {
        console.error(`Error fetching ${unit} playbooks:`, error);
      }
    };

    await fetchByUnit('Offense', setOffensePlaybooks, setSelectedOffensePlaybook);
    await fetchByUnit('Defense', setDefensePlaybooks, setSelectedDefensePlaybook);
    await fetchByUnit('Special', setSpecialPlaybooks, setSelectedSpecialPlaybook);
  };

  // Lade Playbooks beim Mounten oder wenn Organisation/Team sich ändert
  useEffect(() => {
 //   fetchPlaybooks();
  }, [organisationId, teamId]);

  // Context-Werte bereitstellen
  return (
    <PlaybookContext.Provider
      value={{
        offensePlaybooks,
        defensePlaybooks,
        specialPlaybooks,
        selectedOffensePlaybook,
        selectedDefensePlaybook,
        selectedSpecialPlaybook,
        setSelectedOffensePlaybook,
        setSelectedDefensePlaybook,
        setSelectedSpecialPlaybook,
        fetchPlaybooks, 
      }}
    >
      {children}
    </PlaybookContext.Provider>
  );
};

export const usePlaybookContext = () => useContext(PlaybookContext);
