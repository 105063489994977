// src/pages/TeamEventsPage.js
import React, { useState, useEffect } from 'react';
import { Box, Button, Grid } from '@mui/material';
import EventTimeline from '../components/Timeline/EventTimeline';
import AddEventModal from '../components/Timeline/AddEventModal';
import axiosInstance from '../api/axiosInstance';
import useFetchEvents from '../hooks/useFetchEvents'
import ContainerPageHeader from '../components/Header';

const TeamEventsPage = ({ teamId }) => {
  const [openModal, setOpenModal] = useState(false);
  const { events, loading, error, refreshEvents } = useFetchEvents(teamId);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const handleAddEvent = async (newEvent) => {
    try {
      await axiosInstance.post(
        `/teams/${teamId}/${newEvent.event_type === 'Practice' ? 'practices' : 'games'}/`,
        newEvent
      );
      await refreshEvents(); // Aktualisiert Events nach erfolgreicher Erstellung
      setOpenModal(false);
    } catch (error) {
      console.error('Error adding event:', error);
    }
  };

  return (
    <>
      <ContainerPageHeader title="Schedule">
        <Button variant="contained" color="primary" onClick={() => setOpenModal(true)}>
          Neues Event hinzufügen
        </Button>
      </ContainerPageHeader>
      <Grid item xs={12} >
        {/* Lade- und Fehlerzustände */}
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <EventTimeline initialEvents={events} />
        )}
        <AddEventModal open={openModal} onClose={() => setOpenModal(false)} onSave={handleAddEvent} />
      </Grid>
    </>
  );
};

export default TeamEventsPage;
