import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

const Header = ({ title, children }) => {
    return (
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', padding: '0rem 3rem 1rem 0rem', borderBottom: '1px solid #ddd', marginBottom: '0.5rem' }}>
        <Typography variant="h5">{ title }</Typography>
        <div style={{ display: 'flex', gap: '8px' }}>
          { children }
        </div>
      </Box>
    );
};

export default Header;