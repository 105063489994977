// PlaybookTagsTable.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box, Typography, TextField, InputAdornment, IconButton, Button, Select, MenuItem, Modal,
  TablePagination, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupIcon from '@mui/icons-material/Group';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import axiosInstance from '../api/axiosInstance';
import AddTagModal from '../components/AddTagModal';
import Header from '../components/Header';
import { usePlaybookContext } from '../contexts/PlaybookContext'; // Import des Contexts
import { useGlobalContext } from '../contexts/GlobalContext';

const PlaybookPage = ( ) => {
  const { unit, type } = useParams(); 
  const { 
    organisations, selectedOrganisation, selectOrganisation,
    selectedTeam, selectTeam, selectOrgTeam,
    offensePlaybooks, defensePlaybooks, specialPlaybooks,
    selectedOffensePlaybook, selectedDefensePlaybook, selectedSpecialPlaybook,
    setSelectedOffensePlaybook, setSelectedDefensePlaybook, setSelectedSpecialPlaybook
  } = useGlobalContext();

  // Playbooks basierend auf der Unit abrufen
  const playbooks = unit === 'Offense' ? offensePlaybooks : 
                    unit === 'Defense' ? defensePlaybooks : 
                    specialPlaybooks;

  const selectedPlaybook = unit === 'Offense' ? selectedOffensePlaybook : 
                           unit === 'Defense' ? selectedDefensePlaybook : 
                           selectedSpecialPlaybook;

  const setSelectedPlaybook = unit === 'Offense' ? setSelectedOffensePlaybook : 
                              unit === 'Defense' ? setSelectedDefensePlaybook : 
                              setSelectedSpecialPlaybook;
  const [openModal, setOpenModal] = useState(false); 
  const [currentPlaybook, setCurrentPlaybook] = useState(null); // Für Bearbeitung oder neues Playbook
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // Löschen-Dialog
  const [playbookToDelete, setPlaybookToDelete] = useState(null); // Das zu löschende Playbook

  const [editPlaybook, setEditPlaybook] = useState(null);
  const [filteredPlaybooks, setFilteredPlaybooks] = useState([]);
  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name'); 
  const navigate = useNavigate();

  const [firstTypeName, setFirstTypeName] = useState('');

  useEffect(() => {
    setFilteredPlaybooks(playbooks);
  }, [playbooks]);
  // Playbook-Handling
  const handlePlaybookSelect = (playbook) => {
    setSelectedPlaybook(playbook);
  };

  const handleSettingsClick = () => {
    // Navigiert zur PlaybookType-Seite
    navigate(`/terminology/types/${unit}`);
  };


  // Handle Pagination
  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Handle Search
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchQuery(value);
    if (value === '') {
      setFilteredPlaybooks(playbooks);
    } else {
      setFilteredPlaybooks(playbooks.filter(p =>
        p.name.toLowerCase().includes(value) ||
        p.description.toLowerCase().includes(value)
      ));
    }
  };

  // Sortier-Logik
  const handleSortRequest = (property) => {
    const isAscending = 'name' === property;
    setOrder(isAscending ? 'asc' : 'desc');
    setOrderBy(property);

    const sortedData = [...playbooks].sort((a, b) => {
      if (property === 'name') {
        const nameA = a[property]?.toLowerCase() || '';
        const nameB = b[property]?.toLowerCase() || '';
        return isAscending ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
      } else if (property === 'updated_at') {
        const dateA = new Date(a[property]);
        const dateB = new Date(b[property]);
        return isAscending ? dateA - dateB : dateB - dateA;
      }
      return 0;
    });
    setFilteredPlaybooks(sortedData);
  };

  const handleOpenModal = (playbook = null) => {
    setCurrentPlaybook(playbook);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setCurrentPlaybook(null);
    setOpenModal(false);
  };

  const handleSavePlaybook = async () => {
    try {
      if (currentPlaybook?.id) {
        // Bearbeiten
        await axiosInstance.put(`/playbook_management/${selectedOrganisation.id}/${selectedTeam.id}/playbooks/${currentPlaybook.id}/`, {
          name: currentPlaybook.name,
          description: currentPlaybook.description,
          organisation: selectedOrganisation.id,
          team: selectedTeam.id,
          active: currentPlaybook.active,
          unit,
        });
      } else {
        // Erstellen
        await axiosInstance.post(`/playbook_management/${selectedOrganisation.id}/${selectedTeam.id}/playbooks/`, {
          name: currentPlaybook.name,
          description: currentPlaybook.description,
          organisation: selectedOrganisation.id,
          team: selectedTeam.id,
          active: true,
          unit,
        });
      }
      handleCloseModal();
      selectOrgTeam(selectedOrganisation, selectedTeam); // Aktualisiere die Liste
    } catch (error) {
      console.error('Error saving playbook:', error);
    }
  };

  const handleOpenDeleteDialog = (playbook) => {
    setPlaybookToDelete(playbook);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setPlaybookToDelete(null);
    setOpenDeleteDialog(false);
  };

  const handleDeletePlaybook = async () => {
    try {
      await axiosInstance.delete(`/playbook_management/${selectedOrganisation.id}/${selectedTeam.id}/playbooks/${playbookToDelete.id}/`);
      handleCloseDeleteDialog();
      selectOrgTeam(selectedOrganisation, selectedTeam);  // Aktualisiere die Liste
      
    } catch (error) {
      console.error('Error deleting playbook:', error);
    }
  };


  return (
    <>
      <Header title={unit ? `${unit} Playbooks` : `Playbooks`}>
        <TextField
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="outlined"
          size="small"
        />
        {/* Sortierauswahl */}
        <Select
          value={orderBy}
          onChange={(event) => handleSortRequest(event.target.value)}
          variant="outlined"
          size="small"
          sx={{ marginLeft: 2, marginRight: 2}}
        >
          <MenuItem value="name">Sort by Name</MenuItem>
          <MenuItem value="updated_at">Sort by Date</MenuItem>
        </Select>
        <Button 
          variant="contained" 
          color="primary" 
          startIcon={<AddIcon />} 
          onClick={() => handleOpenModal()}
        >
          Add Playbook
        </Button>

      </Header>
      <Grid item xs={12} >
        {/* Aktive Playbooks */}
        <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
          Active Playbooks
        </Typography>
        {filteredPlaybooks
          .filter((playbook) => playbook.active)
          .map((playbook, index) => (
            <Box
              key={index}
              className="playbook-card"
              onClick={() => handlePlaybookSelect(playbook)}
              sx={{
                position: 'relative',
                width: '100%',
                padding: '16px',
                backgroundColor: playbook.id === selectedPlaybook?.id ? '#e0f7fa' : '#f5f5f5',
                borderRadius: '8px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                marginBottom: '1rem',
                cursor: 'pointer',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'scale(1.02)',
                  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              {/* Datum oben rechts */}
              <Typography
                variant="caption"
                sx={{
                  position: 'absolute',
                  top: '8px',
                  right: '16px',
                  color: '#757575',
                  fontSize: '12px',
                }}
              >
                {new Date(playbook.updated_at).toLocaleString()}
              </Typography>
              {/* Titel */}
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {playbook.name}
              </Typography>
              {/* Beschreibung */}
              <Typography variant="body2" sx={{ color: '#757575', marginTop: 1 }}>
                {playbook.description || 'No description available.'}
              </Typography>
              <Box sx={{ position: 'absolute', bottom: '8px', right: '16px' }}>
                {/* Terminology öffnen */}
                <IconButton onClick={() => navigate(`/terminology/${playbook.id}`)} >
                  <SettingsIcon />
                </IconButton>
                <IconButton onClick={() => handleOpenModal(playbook)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleOpenDeleteDialog(playbook)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          ))}

        {/* Inaktive Playbooks */}
        <Typography variant="h6" sx={{ marginTop: 4, marginBottom: 2, fontWeight: 'bold' }}>
          Inactive Playbooks
        </Typography>
        {filteredPlaybooks
          .filter((playbook) => !playbook.active)
          .map((playbook, index) => (
            <Box
              key={index}
              className="playbook-card"
              sx={{
                position: 'relative',
                width: '100%',
                padding: '16px',
                backgroundColor: '#f5f5f5',
                borderRadius: '8px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                marginBottom: '16px',
              }}
            >
              {/* Datum oben rechts */}
              <Typography
                variant="caption"
                sx={{
                  position: 'absolute',
                  top: '8px',
                  right: '16px',
                  color: '#757575',
                  fontSize: '12px',
                }}
              >
                {new Date(playbook.updated_at).toLocaleString()}
              </Typography>
              {/* Titel */}
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {playbook.name}
              </Typography>
              {/* Beschreibung */}
              <Typography variant="body2" sx={{ color: '#757575', marginTop: 1 }}>
                {playbook.description || 'No description available.'}
              </Typography>
              <Box sx={{ position: 'absolute', bottom: '8px', right: '16px' }}>
                {/* Terminology öffnen */}
                <IconButton
                  onClick={() => navigate(`/terminology/${playbook.id}`)}
                  sx={{
                    color: 'primary.main',
                    '&:hover': { color: 'primary.dark' },
                  }}
                >
                  <CategoryIcon />
                </IconButton>
                <IconButton onClick={() => handleOpenModal(playbook)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleOpenDeleteDialog(playbook)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          ))}
      </Grid>

      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredPlaybooks.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/* Modal for Add/Edit Playbook */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            {currentPlaybook?.id ? 'Edit Playbook' : 'Add Playbook'}
          </Typography>
          <TextField
            label="Name"
            value={currentPlaybook?.name || ''}
            onChange={(e) => setCurrentPlaybook({ ...currentPlaybook, name: e.target.value })}
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Description"
            value={currentPlaybook?.description || ''}
            onChange={(e) => setCurrentPlaybook({ ...currentPlaybook, description: e.target.value })}
            fullWidth
            multiline
            rows={3}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
            <Button variant="contained" color="primary" onClick={handleSavePlaybook}>
              Save
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Dialog for Delete Confirmation */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
      >
        <DialogTitle>Delete Playbook</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the playbook "{playbookToDelete?.name}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeletePlaybook} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PlaybookPage;
