import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box, TextField, Typography, Select, MenuItem, Button, Modal, FormControl, Chip, OutlinedInput, Autocomplete, Grid
} from '@mui/material';
import axiosInstance from '../api/axiosInstance';
import { useGlobalContext } from '../contexts/GlobalContext';

const PlayDetailPage = ( ) => {
  const { playId, unit } = useParams(); // Extrahiert `playId` und `unit` aus den Routen-Parametern
  const { 
    offensePlaybooks, defensePlaybooks, specialPlaybooks,
    selectedOffensePlaybook, selectedDefensePlaybook, selectedSpecialPlaybook,
    setSelectedOffensePlaybook, setSelectedDefensePlaybook, setSelectedSpecialPlaybook
  } = useGlobalContext();

  // Playbooks basierend auf der Unit abrufen
  const playbooks = unit === 'Offense' ? offensePlaybooks : 
                    unit === 'Defense' ? defensePlaybooks : 
                    specialPlaybooks;

  const selectedPlaybook = unit === 'Offense' ? selectedOffensePlaybook : 
                           unit === 'Defense' ? selectedDefensePlaybook : 
                           selectedSpecialPlaybook;

  const setSelectedPlaybook = unit === 'Offense' ? setSelectedOffensePlaybook : 
                              unit === 'Defense' ? setSelectedDefensePlaybook : 
                              setSelectedSpecialPlaybook;

  const navigate = useNavigate();
  const [play, setPlay] = useState(null);
  const [selectedPlaybookId, setSelectedPlaybookId] = useState(selectedPlaybook?.id || '');
  const [playbookTypes, setPlaybookTypes] = useState([]);
  const [selectedTags, setSelectedTags] = useState({});
  const [defaultPlaybookTypes, setDefaultPlaybookTypes] = useState([]);
  const [nonDefaultPlaybookTypes, setNonDefaultPlaybookTypes] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedPlaybookType, setSelectedPlaybookType] = useState('');
  const [notes, setNotes] = useState('');
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (playId != "new") {
      fetchPlayDetails(playId);
    }    
    fetchPlaybookTypes(true);  // Lädt PlaybookTypes mit default = true
    fetchPlaybookTypes(false); // Lädt PlaybookTypes mit default = false
  }, [playId, selectedPlaybookId]);

  const fetchPlayDetails = async (id) => {
    try {
      const response = await axiosInstance.get(`/playbook_management/${selectedPlaybookId}/plays/${id}/`);
      setPlay(response.data);
      
      setTitle(response.data.name);
      setSelectedTags(mapTagsToState(response.data.playbook_type_tags));
      setNotes(response.data.description || '');
    } catch (error) {
      console.error('Error fetching play details:', error);
    }
  };

  const fetchPlaybookTypes = async (isDefault) => {
    try {
      const response = await axiosInstance.get(`/playbook_management/${selectedPlaybookId}/playbook-types/?unit=${unit}&default=${isDefault}`);
      const playbookTypesWithTags = response.data.map(type => ({
        ...type,
        tags: type.tags || [],  // Sicherstellen, dass `tags` vorhanden ist
      }));      
      if (isDefault) {
        setDefaultPlaybookTypes(playbookTypesWithTags);
      } else {
        setNonDefaultPlaybookTypes(playbookTypesWithTags);
      }
    } catch (error) {
      console.error('Error fetching playbook types:', error);
    } 
  };

  const handlePlaybookChange = (event) => {
    const newPlaybookId = event.target.value;
    setSelectedPlaybookId(newPlaybookId);
    const newPlaybook = playbooks.find(p => p.id === newPlaybookId);
    setSelectedPlaybook(newPlaybook);
  };

  const handleModalToggle = () => setModalOpen(!isModalOpen);


  const handleSavePlaybookType = () => {
    const selectedType = nonDefaultPlaybookTypes.find(type => type.id === selectedPlaybookType);
    if (selectedType) {
      setDefaultPlaybookTypes([...defaultPlaybookTypes, selectedType]);
      setModalOpen(false);
      setSelectedPlaybookType('');
    }
  };

  const mapTagsToState = (playbookTypeTags) => {
    const tagsState = {};
    playbookTypeTags.forEach(typeTag => {
      tagsState[typeTag.playbook_type_name] = typeTag.tags_output.map(tag => ({ id: tag.id, name: tag.name, active: tag.active, description: tag.description, type: tag.type, playbook: selectedPlaybookId }));
    });
    return tagsState;
  };

  const handleTagChange = (typeName, typeId, event, newValue) => {
    //const tagsToSave = newValue.map(tag => (typeof tag === 'string' ? { id: Date.now(), name: tag } : tag));
    const tagsToSave = newValue.map(tag => (typeof tag === 'string' ? { name: tag, active: true, description: "", type: typeId, playbook: selectedPlaybookId } : tag));

    setSelectedTags((prev) => ({
      ...prev,
      [typeName]: tagsToSave,
    }));
    // Falls ein neues Tag (string) hinzugefügt wird, erweitere das `playbookTypes`-Array dynamisch
    const playbookTypeIndex = defaultPlaybookTypes.findIndex(type => type.name === typeName);
    if (playbookTypeIndex !== -1) {
      const updatedPlaybookTypes = [...defaultPlaybookTypes];
      tagsToSave.forEach(tag => {
          // Überprüfen, ob das Tag bereits existiert
        if (!updatedPlaybookTypes[playbookTypeIndex].tags.some(t => t.name === tag.name && t.id === tag.id)) {
          // Neuen Tag hinzufügen
          updatedPlaybookTypes[playbookTypeIndex].tags.push({ id: tag.id, name: tag.name });  // Temporäre ID
        }
        
      });
      setDefaultPlaybookTypes(updatedPlaybookTypes);
    }
  };

  const handleSave = async () => {
    // Map selectedTags to the format expected by the API
    const playbookTypeTags = Object.keys(selectedTags).map((typeName) => {
      const playbookType = defaultPlaybookTypes.find((type) => type.name === typeName);
      return {
        playbook_type_id: playbookType.id,
        playbook_type_name: playbookType.name,
        tags: selectedTags[typeName],
      };
    });
    const playData = {
      name: title,
      description: notes,
      unit: unit,
      playbook_id: selectedPlaybookId,
      playbook_type_tags: playbookTypeTags,
    };
    try {
      if (playId != "new") {
        await axiosInstance.put(`/playbook_management/${selectedPlaybookId}/plays/${playId}/`, playData);
      } else {
        await axiosInstance.post(`/playbook_management/${selectedPlaybookId}/plays/`, playData);
      }
      navigate(`/plays/${unit}`);
    } catch (error) {
      console.error('Error saving play:', error);
    }
  };

  return (
    <>
      <Typography variant="h5">{playId!="new" ? 'Edit Play' : 'Add New Play'}</Typography>

      {/* Play Info Section */}
      <Grid container spacing={2} sx={{ marginTop: 3 }}>
        <Grid item xs={12} md={6}>
          {/* Playbook Auswahl */}
          <Typography variant="h6">Select Playbook</Typography>
          <FormControl fullWidth sx={{ marginTop: 1 }}>
            <Select
              value={selectedPlaybookId}
              onChange={handlePlaybookChange}
              displayEmpty
            >
              {playbooks.map((playbook) => (
                <MenuItem key={playbook.id} value={playbook.id}>
                  {playbook.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="h6" sx={{ marginTop: 1 }}>Play Info</Typography>
          <TextField
            fullWidth
            label="Play Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            sx={{ marginTop: 2 }}
          />
          <Typography variant="h6" sx={{ marginTop: 1 }}>Notes</Typography>
          <TextField
              label="Notes"
              name="notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              multiline
              rows={4}
              fullWidth
              sx={{ marginTop: 1 }}
          />
        </Grid>

        {/* Playbook Tags Section */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Playbook Tags</Typography>
          {defaultPlaybookTypes.map((type) => (
            
            <Box key={type.id} sx={{ marginTop: 1 }}>
              <Autocomplete
                multiple
                freeSolo
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                value={selectedTags[type.name] || []}
                onChange={(event, newValue) => handleTagChange(type.name, type.id, event, newValue)}
                options={type.tags || []}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                filterSelectedOptions
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    const { key, ...tagProps } = getTagProps({ index }); // Separate key prop from others
                    return (
                      <Chip
                        key={option.id || option.name} 
                        variant="outlined"
                        label={option.name || option}
                        {...tagProps }
                      />);
                })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={type.name}
                    placeholder="Add tags"
                  />
                )}
              />
            </Box>
          ))}
          {/* Button to open Modal */}
          <Button onClick={handleModalToggle} sx={{ marginTop: 2 }} variant="outlined">
            Add Playbook Type
          </Button>
        </Grid>
        <Grid xs={12} sx={{ display: "flex", justifyContent: "flex-end", marginTop: 3 }}>
          {/* Save Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{ marginTop: 2 }}
          >
            Save
          </Button>
        </Grid>
      </Grid>

      

      
      {/* Modal for adding non-default Playbook Types */}
      <Modal open={isModalOpen} onClose={handleModalToggle}>
        <Box sx={{ width: 300, padding: 3, margin: 'auto', marginTop: '10%', backgroundColor: 'white', borderRadius: 1 }}>
          <Typography variant="h6">Add Playbook Type</Typography>
          <Select
            fullWidth
            value={selectedPlaybookType}
            onChange={(e) => setSelectedPlaybookType(e.target.value)}
            displayEmpty
            sx={{ marginTop: 2 }}
          >
            <MenuItem value="" disabled>
              Select a Playbook Type
            </MenuItem>
            {nonDefaultPlaybookTypes.map(type => (
              <MenuItem key={type.id} value={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
          <Button onClick={handleSavePlaybookType} variant="contained" color="primary" sx={{ marginTop: 2 }}>
            Save
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default PlayDetailPage;
