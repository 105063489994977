// contexts/GlobalContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import axiosInstance from "../api/axiosInstance";

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  // 🌍 Organisation & Team State
  const [organisations, setOrganisations] = useState([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);

  // 📖 Playbooks & Playbook Types
  const [offensePlaybooks, setOffensePlaybooks] = useState([]);
  const [defensePlaybooks, setDefensePlaybooks] = useState([]);
  const [specialPlaybooks, setSpecialPlaybooks] = useState([]);

  const [selectedOffensePlaybook, setSelectedOffensePlaybook] = useState(null);
  const [selectedDefensePlaybook, setSelectedDefensePlaybook] = useState(null);
  const [selectedSpecialPlaybook, setSelectedSpecialPlaybook] = useState(null);
  const [offensePlaybookTypes, setOffensePlaybookTypes] = useState([]);
  const [defensePlaybookTypes, setDefensePlaybookTypes] = useState([]);
  const [specialPlaybookTypes, setSpecialPlaybookTypes] = useState([]);
  // 📌 Organisationen & Teams laden
  useEffect(() => {
      fetchOrganisations();
  }, []);

  const fetchOrganisations = async () => {
    try {
      const response = await axiosInstance.get("user_management/users/organisations/");
      setOrganisations(response.data);
      if (response.data.length > 0) {
        selectOrganisation(response.data[0]); // Standard-Organisation auswählen
			}
    } catch (error) {
      console.error("Error fetching organisations:", error);
    }
  };

  const selectOrganisation = (organisation) => {
    setSelectedOrganisation(organisation);
    if (organisation.teams.length > 0) {
      setSelectedTeam(organisation.teams[0]); // Standard-Team auswählen
    }
  };

  useEffect(() => {
    if (selectedOrganisation != null && selectedTeam != null) {
      setSelectedDefensePlaybook([]);
      setDefensePlaybooks([]);
      setSelectedOffensePlaybook([]);
      setOffensePlaybooks([]);
      setSelectedSpecialPlaybook([]);
      setSpecialPlaybooks([]);
      setOffensePlaybookTypes([]);
      setDefensePlaybookTypes([]);
      setSpecialPlaybookTypes([]);
      fetchPlaybooks(selectedOrganisation?.id, selectedTeam?.id);
    }
    
  }, [selectedOrganisation, selectedTeam]);
  
  const selectTeam = (team) => {
    setSelectedTeam(team);
  };

	const selectOrgTeam = (organisation, team) => {
		setSelectedOrganisation(organisation);
		setSelectedTeam(team);
	}

  // 📘 Playbooks für ein Team laden
  const fetchPlaybooks = async (organisationId, teamId) => {
    const units = ["Offense", "Defense", "Special"];
    for (const unit of units) {
      try {
        const response = await axiosInstance.get(`/playbook_management/${organisationId}/${teamId}/playbooks/?unit=${unit}`);
        const playbooks = response.data;

        if (unit === "Offense") {
          setOffensePlaybooks(playbooks);
          if (playbooks.length > 0) setSelectedOffensePlaybook(playbooks[0]);
        } else if (unit === "Defense") {
          setDefensePlaybooks(playbooks);
          if (playbooks.length > 0) setSelectedDefensePlaybook(playbooks[0]);
        } else if (unit === "Special") {
          setSpecialPlaybooks(playbooks);
          if (playbooks.length > 0) setSelectedSpecialPlaybook(playbooks[0]);
        }
      } catch (error) {
        console.error(`Error fetching ${unit} playbooks:`, error);
      }
    }
  };

  // 📗 Playbook-Typen für ein Playbook laden
  const fetchPlaybookTypes = async (playbookId, unit, setState) => {
    if (!playbookId) return;

    try {
      const response = await axiosInstance.get(`/playbook_management/${playbookId}/menu/playbook-types/?unit=${unit}`);
      setState(response.data);
    } catch (error) {
      console.error(`Error fetching playbook types for playbook ${playbookId} mit der unit ${unit}:`, error);
    }
  };

  // 📌 Playbook-Typen automatisch laden, wenn sich ein Playbook ändert
  useEffect(() => {
    fetchPlaybookTypes(selectedOffensePlaybook?.id, "Offense", setOffensePlaybookTypes);
  }, [selectedOffensePlaybook]);

  useEffect(() => {
    fetchPlaybookTypes(selectedDefensePlaybook?.id, "Defense", setDefensePlaybookTypes);
  }, [selectedDefensePlaybook]);

  useEffect(() => {
    fetchPlaybookTypes(selectedSpecialPlaybook?.id, "Special", setSpecialPlaybookTypes);
  }, [selectedSpecialPlaybook]);

  return (
    <GlobalContext.Provider
      value={{
        organisations,
        selectedOrganisation,
        selectOrganisation,
        selectedTeam,
        selectTeam,
				selectOrgTeam,
        offensePlaybooks,
        defensePlaybooks,
        specialPlaybooks,
        selectedOffensePlaybook,
        selectedDefensePlaybook,
        selectedSpecialPlaybook,
        setSelectedOffensePlaybook,
        setSelectedDefensePlaybook,
        setSelectedSpecialPlaybook,
        offensePlaybookTypes,
        defensePlaybookTypes,
        specialPlaybookTypes,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

// 🎯 Custom Hook für einfacheren Zugriff
export const useGlobalContext = () => useContext(GlobalContext);
